const TimetableModel = (json, lang) => {
    let data = []
    json.map((res) => {
        if(res.score) {
        let item = {
            global: false,
            name: ``,
            periode: false,
            message: "",
            is_24_7: res.timetable.is_24_7,
            periode_list: [
            {
                message: false,
                periodeFrom: "",
                periodeMessage: "",
                periodeUntil: "",
            },
            ],
            show_exceptions: false,
            exceptions: [],
            table: {
            monday: [
                {
                closed: false,
                afwijkend: false,
                from: "",
                until: "",
                messageText: "",
                },
            ],
            tuesday: [
                {
                closed: false,
                afwijkend: false,
                from: "",
                until: "",
                messageText: "",
                },
            ],
            wednesday: [
                {
                closed: false,
                afwijkend: false,
                from: "",
                until: "",
                messageText: "",
                },
            ],
            thursday: [
                {
                closed: false,
                afwijkend: false,
                from: "",
                until: "",
                messageText: "",
                },
            ],
            friday: [
                {
                closed: false,
                afwijkend: false,
                from: "",
                until: "",
                messageText: "",
                },
            ],
            saturday: [
                {
                closed: false,
                afwijkend: false,
                from: "",
                until: "",
                messageText: "",
                },
            ],
            sunday: [
                {
                closed: false,
                afwijkend: false,
                from: "",
                until: "",
                messageText: "",
                },
            ],
            global: [
                {
                closed: false,
                afwijkend: false,
                from: "",
                until: "",
                messageText: "",
                },
            ],
            },
        };
        // timetable type
        // item.name = res.timetable.timetable_type.title
        item.name = res.timetable.timetable_type[`title_${lang}`] || res.timetable.timetable_type.title;

        item.id = res.timetable.id
        // fill in records
        if(res.timetable.messages.length > 0) { item.message = res.timetable.messages[0].message}
        if(res.timetable.day_exception.length > 0) { item.show_exceptions = true}

        if (res.timetable.period.length === 1) {
            item.periode_list[0].periodeFrom = res.timetable.period[0].start ? res.timetable.period[0].start : ""
            item.periode_list[0].periodeUntil = res.timetable.period[0].end ? res.timetable.period[0].end : ""
            item.periode_list[0].periodeMessage = res.timetable.period[0].reason ? res.timetable.period[0].reason : ""
            item.periode_list[0].message = res.timetable.period[0].reason ? true : false
        }
        else if(res.timetable.period.length > 1) {
            let periods = []
            res.timetable.period.map((per) => {
                periods.push({
                    "periodeFrom": per.start ? per.start : "",
                    "periodeUntil": per.end ? per.end : "",
                    "periodeMessage": per.reason ? per.reason : "",
                    "message": per.reason ? true : false      
                })
            })
            item.periode_list = periods
        }
        if(item.periode_list.length > 0 && item.periode_list[0].periodeFrom !== "") { item.periode = true}


        res.timetable.day_exception.map((exception, i) => {
            item.exceptions.push({
                "date": exception.date,
                "other": exception.date ? true : false,
                "holliday": exception.holliday,
                "message": exception.message,
                "closed": exception.closed,
                "slots": [],
            })
            let slots = []
            exception.exception_timeslots.map((slot) => {
                slots.push({
                    "from": slot.from_time,
                    "until": slot.to_time,
                    "messageText": slot.message && slot.message.message
                })
            })
            if(slots.length === 0) { 
                slots.push({
                    "from": "",
                    "until": "",
                    "messageText": null
                })
            }
            item.exceptions[i].slots = slots
        })

        res.timetable.records.map((record) => {
            // global hours
            if (record.valid_days.includes('0')) { 
                item.global = true
                
                if (record.timeslots.length === 0) { item.table.global[0] = { afwijkend: true, closed: true, from: "", until: "",}}
                record.timeslots.reverse().map((slot, index) => {
                    if (index === 0) {
                        item.table.global[0] = {
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        }
                    } 
                    else {
                        item.table.global.push({
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        })
                    }
                })
            }
            if (record.valid_days.includes('1')) { 
                if (record.timeslots.length === 0) { item.table.monday[0] = { afwijkend: true, closed: true, from: "", until: "",}}
                record.timeslots.map((slot, index) => {
                    if (index === 0) {
                        item.table.monday[0] = {
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        }
                    } 
                    else {
                        item.table.monday.push({
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        })
                    }
                })
            }
            if (record.valid_days.includes('2')) { 
                if (record.timeslots.length === 0) { item.table.tuesday[0] = { afwijkend: true, closed: true, from: "", until: "",}}
                record.timeslots.map((slot, index) => {
                    if (index === 0) {
                        item.table.tuesday[0] = {
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        }
                    } 
                    else {
                        item.table.tuesday.push({
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        })
                    }
                })
            }
            if (record.valid_days.includes('3')) { 
                if (record.timeslots.length === 0) { item.table.wednesday[0] = { afwijkend: true, closed: true, from: "", until: "",}}
                record.timeslots.map((slot, index) => {
                    if (index === 0) {
                        item.table.wednesday[0] = {
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        }
                    } 
                    else {
                        item.table.wednesday.push({
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        })
                    }
                })
            }
            if (record.valid_days.includes('4')) { 
                if (record.timeslots.length === 0) { item.table.thursday[0] = { afwijkend: true, closed: true, from: "", until: "",}}
                record.timeslots.map((slot, index) => {
                    if (index === 0) {
                        item.table.thursday[0] = {
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        }
                    } 
                    else {
                        item.table.thursday.push({
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        })
                    }
                })
            }
            if (record.valid_days.includes('5')) { 
                if (record.timeslots.length === 0) { item.table.friday[0] = { afwijkend: true, closed: true, from: "", until: "",}}
                record.timeslots.map((slot, index) => {
                    if (index === 0) {
                        item.table.friday[0] = {
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        }
                    } 
                    else {
                        item.table.friday.push({
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        })
                    }
                })
            }
            if (record.valid_days.includes('6')) { 
                if (record.timeslots.length === 0) { item.table.saturday[0] = { afwijkend: true, closed: true, from: "", until: "",}}
                record.timeslots.map((slot, index) => {
                    if (index === 0) {
                        item.table.saturday[0] = {
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        }
                    } 
                    else {
                        item.table.saturday.push({
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        })
                    }
                })
            }
            if (record.valid_days.includes('7')) { 
                if (record.timeslots.length === 0) { item.table.sunday[0] = { afwijkend: true, afwijkend: true, closed: true, from: "", until: "",}}
                record.timeslots.map((slot, index) => {
                    if (index === 0) {
                        item.table.sunday[0] = {
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        }
                    } 
                    else {
                        item.table.sunday.push({
                            closed: false,
                            afwijkend: true,
                            from: slot.from_time,
                            until: slot.to_time,
                            messageText: slot.message && slot.message.message
                        })
                    }
                })
            }
            

        })
        data.push(item)
    }
    else {
        data.push(res)
    }
    })

    return data;
  };
  
  export default TimetableModel;
  